.plussa {
    position: relative;
    right: 15px;
    width: 100vw;
  }

  .sensd {

    position: absolute;
    top: -40px;
    right: 15px;
    color: #605446;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    font-size: 20px;
    text-align: center;
    border: none;
  }

   /* .spac-line-lis{
    width: 80vw;
    margin-left: 10vw;
    padding-top: 1px;
    background-color: rgb(118, 123, 123);
    border: 1px solid rgb(177, 177, 177);
}  */

/* .mas-space-lis{
    width: 100%;
    padding-top: 30px;
} */