


/* @media(max-width:700px){ */

.mai{
  width: 100vw;
  overflow-x: hidden;
 
 
  position: relative;
}


.bdiv {
  display: flex;
  flex-direction: column;

  background-color: white;
 
  width: 100vw;
  margin-bottom: 150px;

}

#get_gr_aa {
  transition: opacity .4s;
}


.raw {
  display: flex;
  flex-direction: column;
  background-color: white;
  background-position: center;
  width: 100vw;
  justify-content: center;
}


.zumu {
  width: 100vw;
}

/* titulo */
.titol {
  color: #352e26;
  font-size: 15pt;
  font-weight: 700;
  padding-left: 8px;
  padding-right: 8px;
}

/* precio */
.titot {

  color: #352e26;
  font-size: 12pt;
  padding-left: 8px;
  padding-right: 8px;

}

/* descripcion */
.tipo {
  color: #352e26;
  font-size: 13pt;
  padding-right: 8px;
  padding-left: 8px;
}

.tit {
  color:#352e26;
  font-size: 13pt;
  padding-right: 8px;
  padding-left: 8px;
}

/* titulo */

.ma8 {
  text-align: center;
  background-color: transparent;
  width: 100vw;
  align-content: center;
  height: max-content;
  padding-top: 2px;
}

/* precio */
.ma9 {
  text-align: center;
  background-color: transparent;
  width: 100vw;
  align-content: center;
}

/* descripcion */
.ma1 {
  text-align: center;
  background-color: transparent;
  width: 100vw;
  height: min-content;
  align-content: center;
}

/* imgen */
.ma2 {
  background-color: white;
  width: 100vw;
  padding-top: 2px;
}

.ma2 img {
  /* display: block; */
  display: flex;
  width: 60vw;
  padding-top: 2px;
  border-radius: 50px;
  margin-left: 20vw;
}

.mafrase {
  text-align: center;
  background-color: transparent;
  width: 90vw;
  height: min-content;
  align-content: center;
}

.gat {
  width: 100vw;
  display: flex;
  color: #404042;
  justify-content: center;
  margin-bottom: 20px;
}


.gatito {
  width: 100vw;
  display: flex;
  color:#404042;
  justify-content: center;
  height: 12pt;
}


.gasala img {
  margin-bottom: -45px;

}


h3 {
  padding-top: 10px;
  /* padding-left: 10px; */
  font-weight: 800;
  font-size: 16pt;
  letter-spacing: 1.8px;
  text-align: center;
  width: 90vw;
}

h3::first-letter {
  font-size: 19pt;
}


h4::first-letter {
  font-size: 16pt;
}

h4 {
  padding-top: 10px;
  font-weight: 800;
  font-size: 13pt;
  letter-spacing: 1.6px;
  text-align: center;
  width: 90vw;

}



.gatmes {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  align-items: baseline;
}



.letter, h6:first-child:first-letter {

  font-size: 13pt;
}

h6 {
  font-weight: 700;
  font-size: 11pt;
  letter-spacing: 1.6px;
  text-align: center;
}

.m_cc:first-letter {
  font-size: 15pt;
}

.m_cc {
  font-size: 13pt;
}

.m_cccc {
  font-size: 16pt;
  color: #2a2a2c;
}


h5 {
  font-weight: 300;
  letter-spacing: 1.6px;
  text-align: center;
}



.gatk {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 90vw;
  height: 20pt;
  text-align: center;
}

.gatka {
  width: 90vw;
  text-align: center;
}

.m_kk {
  font-size: 13pt;
}

.m_ks {
  font-size: 11pt;
}

.m_k {
  font-size: 11pt;
}

.gatkoc {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 90vw;
  height: 20pt;
  text-align: center;
}

.gatkoca {
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 90vw;
  height: 20pt;
  text-align: center;
}

.m_cok {
  color: rgb(12, 117, 117);
  padding-top: 10px;
  letter-spacing: 1.6px;
  font-size: 12pt;
  height: 20pt;
}

.m_cok::first-letter {
  font-size: 15pt;
}

.m_sweet {
  justify-content: center;
  font-size: 13pt;
}

.m_sweet:first-letter {

  font-size: 15pt;
}


.m_ks:first-letter {
  font-size: 12.5pt;
}


.cockta {
  width: 90vw;
  height: fit-content;
}

.cockta img {
  margin-top: -70px;
  width: 90vw;
  height: fit-content;

}

.fota {
  background-color: #948776;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0.5px;
  width: 80vw;
  height: 0.5px;
  margin-left: 10vw;
  margin-right: 10vw;
}

.fot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 12px;
  width: 90vw;
  height: 10px;
}

.fotb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 12px;
  width: 100vw;
  height: 15px;
}

h2{
margin-top: 20px;
color: #3c3939;
text-align: center;
margin-bottom: 20px;


}
.mam{
  background-color: white;
  width: 100vw;

}

.mam img {
  display: flex;
  width: 100vw;
}