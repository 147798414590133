.transit {
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.transit:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  color: green;
  background-color: antiquewhite;
}

.cart-contin {
  width: 100vw;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  background-color: antiquewhite;
}

.w-100 {
  width: 100vw;
}

.txal-ce {
  text-align: center;
}

h6 {
  color: #1d1b1b;
  text-align: center;
}

h5 {
  font-size: 15pt;
  font-weight: 500;
  text-align: center;
  color: #511414;
}

.hbb {
  color: black;
}

.hcc {
  color: black;
}

/* <<<<<<<<<<<<< */
.cart-emp {
  /* background-color: rgb(73, 165, 98); */
  color: black;
}

.start-shopp {
  /* background-color: rgb(178, 111, 24); */
  color: black;
}

/* <<<<<<<<<<<<< */
.titl {
  /* background-color: rgb(101, 24, 178); */
  color: black;
}

.cabece {
  /* background-color: rgb(178, 24, 75); */
  color: black;
}

/* <<<<<<<<<<<<< */
.cart-it {
  /* background-color: rgb(31, 157, 31); */
  color: black;
}

.cart-prod {
  /* background-color: rgb(126, 103, 195); */
  color: black;
}

.cart-preci {
  color: black;
}

/* <<<<<<<<<<<<< */
.btns {
  border: none;
  outline: none;
  cursor: pointer;
  height: 35px;
  width: 35px;
  color: #fbf9f9;
  border-radius: 100%;
  font-size: 30px;
}

.btnsa {
  border: none;
  outline: none;
  cursor: pointer;
  height: 35px;
  width: 35px;
  color: #fbf9f9;
  border-radius: 100%;
  font-size: 14pt;
}

.delet {
  position: absolute;
  right: 10px;
}

.btns-dele {
  background-color: #ee112e;
  text-align: center;
}

.btns-plus {
  background-color: #226dca;
  position: relative;
}

.btns-less {
  background-color: #226dca;
  text-align: center;
  /* vertical-align: text-top; */
  /* margin-bottom: 20px; */
  font-size: 30pt;
  position: relative;
}

.poss {
  display: block;
  position: absolute;
  top: -16px;
  right: 9px;
  text-align: center;
}

.possa {
  display: block;
  position: absolute;
  top: -7px;
  right: 6pt;
  text-align: center;
}

.possi {
  display: block;
  position: absolute;
  text-align: center;
  top: -6pt;
  right: 8px;
}

.btn-clear {
  background-color: #8f919d;
  border: none;
  outline: none;
  cursor: pointer;
  height: 45px;
  width: 120px;
  font-weight: 600;
  color: #c71a1a;
  border-radius: 5px;
  font-size: 11pt !important;
}

.count {
  padding: 0.7rem 0;
  margin: 8px;
}

.bord-1 {
  color: black;
  font-size: 16px;
  font-weight: 700;
  width: 35px;
  border: 0.5px solid #b1b1b1;
}

.alin {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
}

.alin-cont {
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.aling-para {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.displa {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.roww {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.spac-line {
  width: 80vw;
  margin-left: 7vw;
  padding-top: 1px;
  background-color: #767b7b;
  /* border: 1px solid rgb(177, 177, 177); */
}

.spac-line-modal {
  width: 70vw;
  margin-left: 11vw;
  padding-top: 1px;
  background-color: #767b7b;
  /* border: 1px solid rgb(177, 177, 177); */
}

.mas-space {
  width: 100%;
  padding-top: 30px;
}

/* <<<<<<<<<<<<< */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  width: 180px;
  height: 40px;
  font-size: 16pt;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
  margin-bottom: 10px;
}

input[type='text'] {
  width: 220px;
  height: 40px;
  font-size: 16pt;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
  margin-bottom: 10px;
}

input[type='password'] {
  width: 180px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
  margin-bottom: 10px;
}

.btn-conect {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 11pt;
  font-weight: 600;
  height: 45px;
  width: 180px;
  color: #f5efef;
  background-color: #4b79ef;
  border-radius: 7px;
}

.btn-conect-close {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 13pt;
  font-weight: 600;
  height: 35px;
  width: 180px;
  color: #f5efef;
  background-color: #ef9d4b;
  border-radius: 7px;
}

/* <<<<<<<<<<<<< */
.message {
  width: 80vw;
  border-radius: 40%;
}

/* <<<<<<<<<<<<< */
.m-b20s {
  margin-bottom: 20px;
}

.m-b10s {
  margin-bottom: 7px;
}

.m-t20s {
  margin-top: 20px;
}
