@media(min-width:900px){

    body {
    
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        overflow-x: hidden;
        margin: 0;
        background-color: #221b11;
        display: flex;
        flex-direction: column;

    }
    .mov{
        background-color:#221b11 ;

    }

    .mov{
        display: flex;
        flex-direction: row;
    }


    section {
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
       
        text-align: center;
    }
    
    .logo_prin {
          padding-top: 30px;
          padding-bottom: 30px;
      }
    /* .log {
        background-color: #fefcf6;
    } */
    
   
    
    
    
    .mbdr {
        background-color:#221b11 ;
        margin: auto;
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
        justify-content: space-around;
    }
    
    .mbzz {
        margin: auto;
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
        cursor: pointer;
        padding-top: 50px;
        text-align: center;
        justify-content: space-around;
        padding-bottom: 50px;
    }
    
    
    a {
        color: rgb(250, 250, 253);
        text-decoration: none;
        font-size: 16pt;
    }
    /* //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

    .foot {
        position: relative;
        bottom: 0;
    
    }
    
    .foot {
       background-color: #221b11;
       background-size: cover;
    }
    
    .colu{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        background-color: #221b11;
        background-size: 100vw;
    }
    
    .logo {
        display: flex;
        flex-direction: row;
        background-size: 100vw;
        padding-top: 30px;
        padding-bottom: 30px;
        opacity: 1;
    }
    .blanc{
        color: azure;
    }
    
    
    .line{
        background-color: rgb(251, 251, 250);
        padding-top: 1px;
    }
   
    .bandera img:hover{
        transform: scale(1.3);
        transition: ease-in 0.7s;
        transition: ease-out 0.9s;
    }
    
    }