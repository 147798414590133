*{
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}

/* nav{
    background-color: blue;
     height: 35px; 
    

}
.logo{
    float: left;
    color: white;
    font-size: 40px;
    text-transform: capitalize;
    font-weight: bold;
    line-height: 80px;
    padding: 0 80px;
}

nav .menu{
    float: right;
    margin-right: 80px;

}
nav .list_item{
    display: inline-block;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 10px;
    line-height: 80px;

}

nav .list_item:hover{
    color: darkgreen;
}

.bars{
    float: right;
    color: white;
    font-size: 25px;
    margin-right: 50px;
    border-radius: 50%;
    line-height: 80px;
    cursor: pointer;
    display: none;

} */

@media(max-width:992px) {
    .logo{
font-size: 30px;
padding: 0 50px;
    }
    nav .list_item{
font-size: 15px;
    }
}
@media(max-width:768px) {
    *{
        margin: 0;
        padding: 0;
        text-decoration: none;
        list-style: none;
    }
    
    
    /* .logo{
        font-size: 25px;
        padding: 0 30px;


        float: left;
        color: white;
       
        text-transform: capitalize;
        font-weight: bold;
        line-height: 80px;
       
            } */
            .space-nav{
                width: 100vw;
                padding-bottom: 7px;
                background-color: #221b11;
            }
            .m-b20special{
               
                margin-bottom: 20px;
                padding-bottom: 2px;
                width: 100vw;
            }

    .fa-close{
        font-size: 40px;
        color: rgb(189, 179, 179);
        float: right;
        margin-right: 20px;
    }
    .bars{
        display: block;
        /* margin-right: 40px; */
        float: right;
        color: white;
        font-size: 25px;
        position: absolute;
        text-align: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        line-height: 45px;
    
        cursor: pointer;
        background-color: #221b11;
        justify-content: center;
        align-content: center;
        z-index: 9;
        right: 8px;
        top: 12vh;
        
    }
    .nav{
      
        float: right;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        padding-bottom: 3px;
        height: 3px;
        background-color: #221b11;
      
    }
    nav .menu{
        position: absolute;
        top: 10vh;
        background-color: #221b11;
        /* height: 100vh; */
        height: max-content;
        width: 100%;
        transition: all 0.5s;
        z-index: 99;
        float: right;
        list-style: none;
        text-decoration: none;
        
    
    
    }
    nav .list_item{
        display: block;
        text-align: center;
        font-size: 16px;
        margin: 50px 0;
        line-height: 25px;
        color: white;
        text-transform: uppercase;
        list-style: none;
        text-decoration: none;
        
       
        /* line-height: 80px; */
    
    }

    .list_item {
       
        text-decoration: none;
        text-decoration-line: none;
        text-decoration-style: none;
        text-emphasis-style: none;
       
        /* line-height: 80px; */
    
    }

    .selected{
     
        text-decoration: none;
        text-decoration-line: none;
        text-decoration-style: none;
    }
    

    .spac_topna{
        padding-bottom: 300px;
        width: 100vw;
        background-color: rgb(254, 254, 254);
    }

.line-Nav{
    width: 100vw;
    padding-top: 1px;
    background-color: rgb(94, 90, 90);
}


}
    

    
