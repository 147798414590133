
.link p{
color: white;
font-size: 16pt;
font-weight: 500;
text-align: center;

}

.active p{

color: rgb(232, 149, 6);
font-weight: 700;
font-size: 18pt;


}
.list_item:hover{
 
  transform: scale(1.2);
  transition: all ease-in-out 1s;
  box-shadow: 4px white;
}

 .link p:hover{
  color: rgb(232, 149, 6);
  
}

   
  .nav {

    height: 4px;
    background-color: #221b11;
    width: 100%;
    z-index: 99;
    
  }
  
  .sticky {
    position: fixed;
    top: 0;
  }
  
 
  
  
  
  