/* @media(max-width:700px){ */

header{
    display: flex;
    flex-direction: column;
    width: 100vw;
   
   
}




.conner{

    display: flex;
    flex-direction: row;
    width: 100vw;
    align-items: center;
    justify-content: center;
   
   
}


.conner .gr .greek .gre >a{
    
    display: block;
    position: relative;
    cursor: pointer;
    
} 


.gre {
    position: absolute;
    float: left;
    left: 50px;
    padding-bottom: 20px;
}
.gre img{

    width: 25px;
    height: 16px;

}
.greek {
    position: absolute;
    float: right;
    right: 50px;
    padding-bottom: 20px;
    
}

.greek img{

    width: 25px;
    height: 16px;

}


.logo-al {
    width: 100vw;
    height: 14vh;
    display: flex;
   

}

.logo-al img {
    
    margin: 0;
    width: 100vw;
   
   height: 14vh;




}

.garra{
    text-align: center;
   
    font-size: 20pt;
    padding: 3px;
   
}

.te{
    font-size: 13pt;
    color:#fff4e7;
    text-align: left;
    margin-left: 20px;
    
}

.gat{
    width: 100vw;
    text-align: center;
    justify-content: center;

}
.gat img{

width: 50vw;
}
.carta {
  background-color: red; /* For browsers that do not support gradients */
  background-image: radial-gradient(rgb(249, 5, 5), rgb(246, 6, 6), white);
    animation: color 9s infinite linear;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 99;
    right: 6px;
    bottom: 6px;
    text-align: center;
    /* vertical-align: middle; */
    justify-items: center;
   
  }

  @keyframes color {
    0%   { background-image: radial-gradient(red,rgb(241, 99, 99),  white); }
    20%  { background-image: radial-gradient(red,rgb(249, 42, 42),  white); }
    40%  { background-image: radial-gradient(red,red,  white); }
    60%  { background-image: radial-gradient(red,rgb(245, 55, 55),  white); }
    80%  { background-image: radial-gradient(red,rgb(240, 96, 96),  white); }
    100% { background-image: radial-gradient(red,rgb(246, 137, 137),  white); }
  }
  
  @keyframes App-logo-sp {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
   
  }


.battt:hover {
    background-color: #0ba85e;
    /* box-shadow: 10px 10px 5px lightblue; */
  }
  
  .battt {
    /* animation: color 9s infinite linear; */
    /* animation: App-logo-sp infinite 20s linear; */
    color: aliceblue;
     background-color: transparent; 
    background-image: url("../../public/data/collec/nota_re.png");
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: 50px;
    border: none;
    font-size: 12pt;
    border-radius: 100%;
    pointer-events: visible;
  }
  